<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import { Search } from "lucide-vue-next";
import {
  ComboboxInput,
  type ComboboxInputProps,
  useForwardProps,
} from "radix-vue";
import { cn } from "@/lib/utils";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<
  ComboboxInputProps & {
    class?: HTMLAttributes["class"];
  }
>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <div
    class="relative flex items-center border focus-within:border-primary rounded-lg px-4 group focus-within:bg-primary-light"
    cmdk-input-wrapper
  >
    <Search
      class="h-4 w-4 shrink-0 transition-colors duration-200 group-focus-within:text-primary"
    />
    <ComboboxInput
      v-bind="{ ...forwardedProps, ...$attrs }"
      auto-focus
      :class="
        cn(
          'flex h-11 w-full rounded-md bg-transparent py-8 px-6 text-base outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
          props.class
        )
      "
    />
  </div>
</template>
