<template>
  <div class="p-6 lg:py-8 2xl:w-[1400px] grow">
    <header class="flex justify-between">
      <a :href="getWebsiteURL()">
        <img src="~/assets/images/logo.png" class="w-48 lg:block hidden" />
        <img src="~/assets/images/logo-minimal.png" class="w-16 lg:hidden" />
      </a>
      <div class="hidden lg:flex items-center gap-x-4">
        <div class="hidden sm:block">Need Help?</div>
        <Button
          variant="outline"
          class="lg:py-6 lg:px-8 text-md"
          as="a"
          :href="`tel:${getContactNumber()}`"
        >
          Call {{ getContactNumber() }}
        </Button>
      </div>
      <div class="lg:hidden flex items-center gap-x-4">
        <Popover>
          <PopoverTrigger class="flex items-center gap-x-3">
            <InformationSquare
              class="w-6 h-6"
              :fontControlled="false"
              filled
              v-auto-animate
              v-if="isRegular()"
            />
            <h1 v-auto-animate>${{ getTotal() }}</h1>
            <ChevronDown />
          </PopoverTrigger>
          <PopoverContent
            class="p-0 m-0 border-none rounded-none lg:rounded-xl w-screen mt-7 left-0 lg:hidden shadow-lg"
          >
            <BookingSummary />
          </PopoverContent>
        </Popover>
      </div>
    </header>
    <section class="lg:mt-8 mt-4 sm:mt-4 flex-grow -mx-6 lg:-mx-0">
      <Progress class="h-1" :model-value="Math.round((form.step / 6) * 100)" />
    </section>
    <main class="xl:mt-16 mt-8">
      <FormDomestic />
    </main>
  </div>
  <footer class="py-2">
    <div class="container mx-auto flex justify-center items-center">
      <span class="text-sm text-muted">v{{ versionString }}</span>
    </div>
  </footer>
  <Toaster />
</template>

<script setup lang="ts">
import { Progress } from "@/components/ui/progress";
import { useBookingStore } from "~/store";
import { ChevronDown } from "lucide-vue-next";
import InformationSquare from "~/assets/svg/information-square.svg";
import BookingSummary from "@/components/form/BookingSummary.vue";
import Toaster from "@/components/ui/toast/Toaster.vue";

const {
  form,
  getTotal,
  isRegular,
  getWebsiteURL,
  getContactNumber,
  setCountry,
} = useBookingStore();
const config = useRuntimeConfig();
const versionString = ref(config.public.versionString);

onBeforeMount(async () => {
  const country = window.location.origin.endsWith("co.nz") ? "nz" : "au";
  setCountry(country);
});

useHead({
  title: "Absolute Domestics | Book Online",
  meta: [
    {
      name: "description",
      content: "Book a cleaning service with Absolute Domestics.",
    },
  ],
  htmlAttrs: {
    lang: "en",
  },
});
</script>
